<template>
  <div>
    <div class="sourceAdd">
      <form-panel
        ref="formPanel"
        :form="form"
        v-bind="submitConfig"
        :submitText="'完成'"
        :label-position="'left'"
      >
        <div class="title">基本信息</div>
        <el-form-item
          label="模型名称"
          :rules="[{ required: true, message: '请输入名称', trigger: 'blur' }]"
          prop="modelName"
        >
          <v-input
            placeholder="请输入数据源名称"
            v-model="form.modelName"
          ></v-input>
        </el-form-item>
        <div class="title">数据源输入</div>
        <el-form-item
          label="模型数据更新周期"
          :rules="[
            { required: true, message: '请输入表达式', trigger: 'blur' },
          ]"
          prop="address"
        >
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in sceneIdsList"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="checkChange"
            />
            <v-input
              placeholder="请输入数据源名称"
              v-model="form.loginName"
            ></v-input>
          </div>
        </el-form-item>
        <div class="title">数据转换</div>
        <el-form-item
          label="选择数据源"
          :rules="[
            {
              required: true,
              message: '请选择数据源',
              trigger: 'blur',
            },
          ]"
          prop="loginName"
        >
          <v-select clearable :options="options" />
        </el-form-item>
        <el-form-item
          label="数据过滤"
          :rules="[
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
          ]"
          prop="loginName"
        >
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in filtOpt"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="goFiltration()"
            />
          </div>
        </el-form-item>
        <el-form-item
          label="数据计算"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]"
          prop="loginName"
        >
          <div class="check-panel">
            <v-checkbox
              v-model="item.checked"
              v-for="(item, index) in compOpt"
              :key="index"
              :index="index"
              type="border"
              :label="item.label"
              @change="goComputed()"
            />
          </div>
        </el-form-item>
        <template #saveBeforeSlot>
          <v-button text="取消"> </v-button>
        </template>
      </form-panel>
      <div>
        <v-dialog
          title="数据过滤"
          v-model="dialogFit"
          width="50%"
          @confirm="confirm(1)"
        >
          <form-panel ref="formPanel" :form="form" :hasHeader="false">
            <el-form-item
              label="模型名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入数据源名称"
                v-model="form.loginName"
              ></v-input
            ></el-form-item>
            <el-form-item
              label="条件关系"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options"
            /></el-form-item>

            <el-form-item
              label="过滤条件"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-table
                class="item-table"
                :tableData="form.requestList"
                ref="vTable"
                :headers="headers"
                :isOperateColumn="false"
              >
              </v-table>
            </el-form-item>
            <el-form-item
              label="指标索引"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options"
            /></el-form-item>
          </form-panel>
        </v-dialog>
        <v-dialog
          title="数据计算"
          v-model="dialogComputed"
          width="50%"
          @confirm="confirm(2)"
        >
          <form-panel ref="formPanel" :form="form" :hasHeader="false">
            <el-form-item
              label="算子名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入数据源名称"
                v-model="form.loginName"
              ></v-input
            ></el-form-item>
            <el-form-item
              label="条件关系"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options"
            /></el-form-item>

            <el-form-item
              label="计算条件"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-input
                placeholder="请输入数据源名称"
                v-model="form.loginName"
              ></v-input>
            </el-form-item>
            <el-form-item
              label="计算后字段"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="loginName"
            >
              <v-select clearable :options="options"
            /></el-form-item>
          </form-panel>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "right",
      dialogFit: false,
      dialogComputed: false,
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      submitConfig: {
        queryUrl: "aaa",
        submitUrl: "bb",
      },
      sceneIdsList: [
        { checked: false, label: "实时" },
        { checked: false, label: "固定周期（cron表达式）" },
      ],
      filtOpt: [{ checked: false, label: "数据过滤" }],
      compOpt: [{ checked: false, label: "数据计算" }],
      form: {
        id: "", // 数据唯一值
        userName: "", // 姓名（昵称）
        mobileNum: "", // 手机号
        loginName: "", // 登陆账户
        loginPwd: "", // 登陆密码
        newPasswordRepeat: "", // 确认密码
        manageGroupId: "", // 管理组id
        roleIds: "", // 角色id
        userSource: 3, // 固定
        isRegister: 4, // 固定
        requestList: [
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
          {
            problemType: 1,
            problemName: "aaa",
          },
        ],
      },
      headers: [
        {
          prop: "problemName",
          label: "名称",
        },
        {
          prop: "problemType",
          label: "备注",
        },
        {
          prop: "ifRequired",
          label: "类型",
        },
      ],
    };
  },
  mounted() {
    this.$setBreadList("新建模型");
  },
  methods: {
    // 确定
    confirm(type) {
      type == 1 ? (this.dialogFit = false) : (this.dialogComputed = false);
    },
    checkChange(value) {
      console.log(value);
    },
    goFiltration(value) {
      this.dialogFit = true;
    },
    goComputed() {
      this.dialogComputed = true;
    },
  },
};
</script>

<style lang="less" scoped>
.sourceAdd {
  margin-left: 110px;
  .ml {
    margin-left: 20px;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  box-sizing: border-box;
  height: 100%;
  /deep/ .el-form-item__label {
    width: 210px !important;
  }
  /deep/ textarea {
    height: 150px;
  }
  .workSheet {
    padding: 40px;
    width: 300px;
    height: 300px;
    overflow-y: auto;
  }
  .check-panel {
    display: flex;
  }
  ::v-deep .el-table {
    height: 200px;
  }
  .item-table {
    height: 200px;
  }
}
</style>
